<template>
  <CardSolution v-if="props.cardsPayload.cards_type === 'solution'" :sc-payload="cardsPayload" />

  <CardWaste v-if="props.cardsPayload.cards_type === 'waste'" :card-item="cardsPayload" />

  <CardBenefit v-if="props.cardsPayload.cards_type === 'benefit'" :sc-payload="cardsPayload" />
</template>

<script lang="ts" setup>
import type { Cards } from '~/types/types'

const props = defineProps<{
  cardsPayload: Cards
}>()
</script>
